import React, { Component } from "react";

// import Boost from './assets/Boost.svg'
import Guide from "./assets/Guide.svg";
import Note from "./assets/Note.svg";
import Star from "./assets/Star.svg";
import Time from "./assets/Time.svg";
import Wrench from "./assets/Wrench.svg";
import bg from "./assets/bg.svg";
import bg2 from "./assets/bg2.svg";

import { Link } from "react-router-dom";

import "./index.scss";
import {
  BUCHAREST_LINK,
  // BRASOV_LINK,
  CLUJ_LINK,
  IASI_LINK,
  // SIBIU_LINK,
  TIMISOARA_LINK,
} from "../../Home/RegisterNow";
// import RegisterButton from '../../Common/RegisterButton'

const icons = [
  // {
  //   title: 'Pre-recruiting',
  //   subtitle: 'December - January',
  //   image: Note,
  //   description:
  //     'In this early preparing phase, you are encouraged to create and formulate your idea in Idea Jam events and mentors – students’ meet-ups. Bring your own ideas to the table or build them with the help of mentors, partners or program alumni, in a series of dynamic and informal set-ups. If you have an idea that you want to discuss with our mentors, please use the pre-registration form. If you want more details on the technology enablers provided by our partners – check out the about section. ',
  //   Content: () => <div><br/><br/></div>
  // },
  {
    title: "Registration",
    subtitle: "December - February",
    image: Note,
    description:
      "	In this initial preparation phase, we encourage you to develop and shape your ideas at Idea Jam events and student-mentor meet-ups. Bring your own concepts or create new ones with support from mentors, partners, and program alumni in a series of dynamic and informal sessions. Not quite sure about your idea but ready to dive in? Explore the Open Challenges on our landing page—these specially curated concept cards, crafted by Innovation Labs and our partners, are designed to spark inspiration. For those eager to secure their place, the pre-registration form is available. For further details on the technology enablers provided by our partners, be sure to visit the Tech section.",
    Content: () => <div></div>,
  },
  {
    title: "Selection Hackathons",
    subtitle:
      "8 & 9 March - Bucharest Innovation Labs Hackathon | 22 & 23 March - Cluj, Iasi, and Timisoara Innovation Labs Hackathons",
    image: Wrench,
    description:
      "Welcome to the beginning of your start-up adventure! Join us in one of the 30-hours Hackathons – and pitch your idea in 1 minute in front of the public. Inspire the audience, find team members, and go on to build your prototype - with mentorship and lots of coffee. Then hack through the night and discuss and improve your product based on feedback from mentors.  At the end, pitch your prototype in front of the jury, renowned IT professionals, business gurus, tech-angels – and keep your fingers crossed for the selection. If you manage to convince the jury about the feasibility of your idea, you are selected to join the mentorship program. Congratulations, you have just won three months of hard work!",
    Content: () => <></>,
  },
  {
    title: "Pre-acceleration phase",
    subtitle: "March - May",
    image: Guide,
    description:
      "Once you are in the program, amazing things are about to happen in the next months which we call the “development phase”. You will attend weekly workshops and inspirational Innovation Labs Unplugged sessions that will engage you with the entrepreneurial ecosystem and provide food for thought! Empowered by your mentors and assigned coaches, you will be assisted in developing your product on various dimensions, and creating your own entrepreneurial mindset.",
    Content: () => (
      <div className="guides">
        <span> Workshops happening during the program: </span>
        <br />
        <span>How to Build an MVP in Innovation Labs</span>
        <span>UX and Customer Discovery</span>
        <span>Competitive Analysis</span>
        <span>Business models</span>
        <span>Persona identification</span>
        <span>Feature prioritisation</span>
        <span>User Journey & Info Architecture</span>
        <span>Software Architecture & Tech Stack</span>
        <span>User Testing </span>
        <span>Cybersecurity basics</span>
        <span>Patenting</span>
        <span>How to Pitch</span>
        <br />
      </div>
    ),
  },
  // {
  //   title: 'Boost Day',
  //   subtitle: '3-12 April',
  //   image: Boost,
  //   description:
  //     'In this intermediary boost phase, you will have the opportunity to validate your progress after presenting your product in front of an in-house audience. This checkpoint will allow you to obtain valuable feedback from mentors, program ambassadors and also from other teams enrolled in the program. ',
  //   Content: () => null
  // },
  {
    title: "Demo Day",
    subtitle: "26 May",
    image: Star,
    description:
      "You’ve made it to the most intense event of the journey! The program culminates with the graduating events that gather teams, mentors, and ambassadors, partners of the program as well as different stakeholders from the educational and entrepreneurial ecosystem. The first event is the “Startups Fair”, where you will be presenting your technical demo and networking with our various stakeholders. The second event is the “Demo Day” where you will hold a 2 minutes’ pitch in front of the audience, showcasing the product you have built over the past three months, and will compete for the jury’s recognition regarding your efforts in turning your idea into an MVP over the program. These exciting event put the best teams and products in the spotlight, allowing you to benefit from a strong media exposure and an audience of approximately 600 possible partners and investors.",
    Content: () => (
      <div>
        {/* <iframe title = "demoDay" src="https://www.facebook.com/plugins/post.php?href=https%3A%2F%2Fwww.facebook.com%2FILabsRo%2Fvideos%2F838887483549487%2F&width=500&show_text=false&height=281&appId" width="700" height="281"  scrolling="no" frameborder="0" allowfullscreen="true" allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share"></iframe>
       <iframe title = "demoDay2" src="https://www.facebook.com/ILabsRo/videos/329145398984969"></iframe> */}
      </div>
    ),
  },
  {
    title: "Long-term support",
    subtitle: "June and beyond",
    image: Time,
    description:
      "Your journey does not end with the Demo Day event. Innovation Labs supports teams that choose to continue working on their product and still need technical guidance and advice on certain issues, such as scaling their start-up or applying for specialized accelerator programs. Also, you will be encouraged to reach out to the world by promoting your products in media appearances that Innovation Labs can facilitate for you. ",
    Content: () => (
      <div className="centered">
        <Link to="/teams">
          <button>see teams</button>
        </Link>
      </div>
    ),
  },
];

class NewTimeline extends Component {
  render() {
    return (
      <div className="NewTimeline">
        <h1>Program structure</h1>
        <div className="items">
          {icons.map((Icon) => (
            <div className="item">
              <img alt="" src={bg2} className="borderSide" />
              <img alt="" src={bg} className="borderTop" />
              <div className="icon">
                <img src={Icon.image} alt="" />
                <div className="text">
                  <h1>{Icon.title}</h1>
                  {Icon.subtitle.split("|").map((s) => (
                    <h2 key={s}>{s}</h2>
                  ))}
                </div>
              </div>
              <div className="description">
                <p>{Icon.description}</p>
              </div>
              <div className="content">
                <Icon.Content schedule={this.props.schedule} />
              </div>
            </div>
          ))}
        </div>
      </div>
    );
  }
}

export default NewTimeline;
